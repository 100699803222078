import { Inject, Injectable } from '@angular/core';
import { Action, NgxsOnInit, Select, Selector, State, StateContext } from '@ngxs/store';
import { FeaturesGet, FeatureSet, FeatureSetFromParams, FeatureRemove } from '../actions/features.action';
import { FeatureService } from '../../services/feature.service';
import { FeatureModel } from '../../models';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';

export class FeatureStateModel {
  features: FeatureModel;
}

@State<FeatureStateModel>({
  name: 'featuresState',
  defaults: {
    features: environment.features
  }
})
@Injectable()
export class FeaturesState implements NgxsOnInit {
  @Select(FeaturesState.selectFeatures) features$: Observable<FeatureModel>;

  constructor(private featureService: FeatureService, @Inject('environment') private environment) {
    this.features$.subscribe((features) => {
      this.featureService.features = features;
    });
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.addMissingKeys(ctx, this.environment);
    this.removeMissingKeys(ctx, this.environment);
  }

  @Selector()
  static selectFeatures(state: FeatureStateModel) {
    return state.features;
  }

  @Action(FeaturesGet)
  featuresGet(ctx: StateContext<FeatureStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      features: this.featureService.get() //here the data coming from the API will get assigned to the users variable inside the appstate
    });
  }

  @Action(FeatureSet)
  featureSet(ctx: StateContext<FeatureStateModel>, { name, value }: FeatureSet) {
    const features = this.featureService.setValue(name, value);
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      features
    });
    return state;
  }

  @Action(FeatureRemove)
  featureRemove(ctx: StateContext<FeatureStateModel>, { name }: FeatureRemove) {
    const features = this.featureService.get();
    delete features[name];
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      features
    });
    return state;
  }

  @Action(FeatureSetFromParams)
  featureSetFromParams(ctx: StateContext<FeatureStateModel>, data: { params: Params }) {
    const features = this.featureService.setFeatureStateFromParams(data.params);
    // const features = states.features;
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      features
    });
    return state;
  }
  // this function will add any keys that we add new in the environment, else they will not be available if the state was stored previously
  private addMissingKeys(ctx: StateContext<any>, environment) {
    const featuresFromState = ctx.getState().features;
    const featuresFromEnv = environment.features;
    const missingKeysInState = Object.keys(featuresFromEnv).filter((key) => typeof featuresFromState[key] === 'undefined');
    missingKeysInState.forEach((key) => {
      ctx.dispatch(new FeatureSet(key, featuresFromEnv[key]));
    });
  }

  private removeMissingKeys(ctx: StateContext<any>, environment) {
    const featuresFromState = ctx.getState().features;
    const featuresFromEnv = environment.features;
    const missingKeysInEnv = Object.keys(featuresFromState).filter((key) => typeof featuresFromEnv[key] === 'undefined');
    missingKeysInEnv.forEach((key) => {
      ctx.dispatch(new FeatureRemove(key));
    });
  }
}
