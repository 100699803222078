import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppHopState, LanguageSet } from 'src/@hop/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'hop-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss']
})
export class LanguageMenuComponent implements OnInit {
  @Select(AppHopState.selectLanguage) language$: Observable<any>;

  nameLangFlag = 'en';
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.language$.pipe(untilDestroyed(this)).subscribe((language) => {
      if (language?.name) {
        this.nameLangFlag = language.name;
      }
    });
  }

  selectLang(lang) {
    this.store.dispatch(new LanguageSet({ name: lang, isManuallySet: true }));
  }
}
