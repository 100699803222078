import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { UserGet, UserState } from '../../state';
import { Select } from '@ngxs/store';
import { User } from '../../models';
import { Observable } from 'rxjs';

@Component({
  selector: 'hop-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  @Select(UserState.selectUser) user$: Observable<User>;
  @Input() customTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}
}
