import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  getAll() {
    return this.http.get<User[]>(`${this.environment.apiUrl}/users`);
  }

  getUser() {
    return this.http.get<User>(`${this.environment.apiUrl}/profile`);
  }
  saveUser(user) {
    return this.http.patch<User>(`${this.environment.apiUrl}/profile`, user);
  }
}
