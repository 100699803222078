import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerifyEmailInfoComponent } from './verify-email-info.component';
import { DialogVerifyEmailComponent } from './dialog-verify-email/dialog-verify-email.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [VerifyEmailInfoComponent, DialogVerifyEmailComponent],
  imports: [CommonModule, MatDialogModule, MatIconModule, MatFormFieldModule, ReactiveFormsModule, MatInputModule, MatButtonModule],
  exports: [VerifyEmailInfoComponent]
})
export class VerifyEmailInfoModule {}
