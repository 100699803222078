<div
  (mouseenter)="collapseOpenSidenav()"
  (mouseleave)="collapseCloseSidenav()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async"
  class="sidenav flex flex-col"
>
  <div class="hop-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex-none flex items-center">
      <img [src]="imageUrl$ | async" alt="Logo" class="w-6 select-none flex-none" />
      <h2 class="hop-sidenav-toolbar__headline flex-auto font-mono">{{ title$ | async }}</h2>
      <button
        (click)="toggleCollapse()"
        *ngIf="showCollapsePin$ | async"
        class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block"
        mat-icon-button
        type="button"
      >
        <mat-icon *ngIf="!collapsed" class="text-[0.875rem] w-[0.875rem] h-[0.875rem]" svgIcon="mat:radio_button_checked"></mat-icon>
        <mat-icon *ngIf="collapsed" class="text-[0.875rem] w-[0.875rem] h-[0.875rem]" svgIcon="mat:radio_button_unchecked"></mat-icon>
      </button>
    </div>

    <div *ngIf="searchVisible$ | async" class="hop-sidenav-search__container">
      <div class="hop-sidenav-search relative" (click)="openSearch()" matRipple matRippleColor="var(--sidenav-item-ripple-color)">
        <mat-icon class="flex-none hop-sidenav-search__icon" svgIcon="mat:search"></mat-icon>
        <div class="flex-auto hop-sidenav-search__placeholder">Quick Search</div>
        <div class="flex-none hop-sidenav-search__keybind">Ctrl K</div>
      </div>
    </div>

    <div class="hop-sidenav-toolbar__divider"></div>
  </div>

  <hop-scrollbar class="flex-1 flex flex-col">
    <div class="pt-6 flex-1">
      <hop-sidenav-item *ngFor="let item of navigationService.items$ | async; trackBy: trackByRoute" [item]="item" [level]="0"></hop-sidenav-item>
    </div>

    <div class="hop-sidenav-footer flex items-center justify-center opacity-20 px-6 py-6 select-none flex-none">
      <img [src]="imageUrl$ | async" alt="Logo" class="w-8 select-none" />
    </div>
  </hop-scrollbar>

  <div *ngIf="userVisible$ | async" class="hop-sidenav-user__container flex-none">
    <div
      #userProfileMenuOriginRef
      (click)="openProfileMenu(userProfileMenuOriginRef)"
      [class.hop-sidenav-user--open]="userMenuOpen$ | async"
      class="hop-sidenav-user flex items-center"
      matRipple
      matRippleColor="var(--sidenav-item-ripple-color)"
    >
      <img alt="User Avatar" class="hop-sidenav-user__image flex-none" src="assets/img/avatars/1.jpg" />
      <div class="hop-sidenav-user__content flex-auto">
        <div class="hop-sidenav-user__title">David Smith</div>
        <div class="hop-sidenav-user__subtitle">Product Manager</div>
      </div>
      <mat-icon class="hop-sidenav-user__dropdown-icon flex-none" svgIcon="mat:arrow_drop_down"></mat-icon>
    </div>
  </div>
</div>
