import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { User } from 'src/@hop/models';
import { UserState } from 'src/@hop/state';
import { DialogVerifyEmailComponent } from './dialog-verify-email/dialog-verify-email.component';

@UntilDestroy()
@Component({
  selector: 'hop-verify-email-info',
  templateUrl: './verify-email-info.component.html',
  styleUrls: ['./verify-email-info.component.scss']
})
export class VerifyEmailInfoComponent {
  @Select(UserState.selectUser) user$: Observable<User>;
  isOpenDialog = false;

  constructor(public dialog: MatDialog) {}

  openDialog() {
    this.isOpenDialog = true;
    this.dialog
      .open(DialogVerifyEmailComponent, {
        panelClass: 'dialog-open'
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => (this.isOpenDialog = false));
  }
}
