import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { LanguageMenuModule } from '../../components/language-menu/language-menu.module';
import { ButtonThemeDarkModule } from '../../components/button-theme-dark/button-theme-dark.module';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDividerModule, MatMenuModule, LanguageMenuModule, ButtonThemeDarkModule],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule {}
