import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationService } from '../../services';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import { Observable, of } from 'rxjs';
import { User } from '../../models';
import { ColorSchemeName } from '../../config/colorSchemeName';
import { HopConfig } from 'src/@hop/config/hop-config.interface';
import { NavigationLink } from 'src/@hop/interfaces/navigation-item.interface';
import { Select, Store } from '@ngxs/store';
import { AppHopState, UserState } from '../../state';
import { ColorSchemeSet } from '../../state';
import { AppState } from '../../../app/state/states/app-state.service';

@Component({
  selector: 'hop-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
  @Input() mobileQuery: boolean;
  @Input() user: User = null;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  config$: Observable<HopConfig> = this.configService.config$;

  @Select(UserState.selectUser) user$: Observable<ColorSchemeName>;

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(map((config) => config.layout === 'horizontal'));
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(map((config) => config.layout === 'vertical'));
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(map((config) => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(map((config) => config.navbar.position === 'below-toolbar'));
  userVisible$: Observable<boolean> = this.configService.config$.pipe(map((config) => config.toolbar.user.visible));
  megaMenuOpen$: Observable<boolean> = of(false);

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    public navigationService: NavigationService,
    private popoverService: PopoverService,
    private store: Store
  ) {}

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          }
        ]
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }
}
