import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { User } from '../../../../models/user';
import { AuthenticationService } from 'src/@hop/services';
import { Store } from '@ngxs/store';
import { UserLogout } from 'src/@hop/state/actions/user.action';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@UntilDestroy()
@Component({
  selector: 'hop-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit, OnDestroy {
  user: User;
  items: MenuItem[] = [
    {
      id: '1',

      icon: 'mat:settings',
      label: 'Account Settings',
      description: 'Personal Information',
      colorClass: 'text-teal',
      route: '/account'
    },
    {
      id: '2',
      icon: 'mat:account_circle',
      label: 'My Profile',
      description: 'Edit your account page',
      colorClass: 'text-primary',
      route: '/public-profile'
    }
    // {
    //   id: '3',
    //   icon: 'mat:man',
    //   label: 'About You',
    //   description: 'Informations about yourself',
    //   colorClass: 'text-amber',
    //   route: '/about-you'
    // },
    // {
    //   id: '4',
    //   icon: 'mat:table_chart',
    //   label: 'Billing Information',
    //   description: 'Pricing & Current Plan',
    //   colorClass: 'text-purple',
    //   route: '/pages/pricing'
    // }
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green'
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;

  constructor(
    private cd: ChangeDetectorRef,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private store: Store,
    private translateService: TranslateService
  ) {
    this.user = this.popoverRef.data.user;
    this.translateService
      .get(['account-settings', 'personal-info', 'my-profile', 'edit-account-page'])
      .pipe(untilDestroyed(this))
      .subscribe((translations) => {
        this.items[0].label = translations['account-settings'];
        this.items[0].description = translations['personal-info'];
        this.items[1].label = translations['my-profile'];
        this.items[1].description = translations['edit-account-page'];
      });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    //
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }
  logout() {
    this.store.dispatch(new UserLogout());
  }
}
