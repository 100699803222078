<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button [matMenuTriggerFor]="menu" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <!-- <ng-container class="m-12" *ngFor="let item of navigationItems">
      <button [routerLink]="item?.route" mat-menu-item>
        <mat-icon [svgIcon]="item?.icon"></mat-icon>
        <span>{{ item?.label }}</span>
      </button>
    </ng-container> -->
    <hop-sidenav-item *ngFor="let item of navigationService.items$ | async; trackBy: trackByRoute" [item]="item" [level]="0"></hop-sidenav-item>
  </mat-menu>

  <a *ngIf="isVerticalLayout$ | async" [routerLink]="[(user$ | async) ? '/dashboard' : '/']" class="ltr:mr-4 rtl:ml-4 block flex items-center flex">
    <mat-icon svgIcon="logo:logo"></mat-icon>
    <h1 class="title ltr:pl-4 rtl:pr-4 m-0 select-none">koSchedule</h1>
  </a>

  <!--<button *ngIf="isHorizontalLayout$ | async"
          [class.hidden]="mobileQuery"
          [matMenuTriggerFor]="addNewMenu"
          color="primary"
          mat-flat-button
          type="button">Add New
  </button>-->

  <!--<mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:person_add"></mat-icon>
      <span>Add Contact</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:done_all"></mat-icon>
      <span>Add Task</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment_turned_in"></mat-icon>
      <span>Add Project</span>
    </button>

    <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
      <mat-icon svgIcon="mat:ballot"></mat-icon>
      <span>Add Document</span>
    </button>
  </mat-menu>-->

  <!--<mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:description"></mat-icon>
      <span>Add Quote</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment"></mat-icon>
      <span>Add Invoice</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:receipt"></mat-icon>
      <span>Add Receipt</span>
    </button>
  </mat-menu>-->

  <!--<div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">
    <button *ngIf="isHorizontalLayout$ | async"
            [class.hidden]="mobileQuery"
            (click)="openMegaMenu(megaMenuOriginRef)"
            [ngClass]="{ 'bg-primary/10': megaMenuOpen$ | async }"
            color="primary"
            mat-button
            type="button">
      <span>Mega Menu</span>
      <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_down"></mat-icon>
    </button>
  </div>-->

  <span class="flex-1"></span>
  <div class="-mx-1 flex items-center">
    <!--<div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
      </button>
    </div>-->

    <!--<div class="px-1">
      <hop-toolbar-notifications></hop-toolbar-notifications>
    </div>-->

    <!-- <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>
      </button>
    </div> -->

    <hop-button-theme-dark class="hidden sm:block"></hop-button-theme-dark>

    <hop-language-menu></hop-language-menu>
  </div>

  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async" [class.hidden]="mobileQuery" class="px-gutter flex-none flex items-center">
    <hop-navigation-item *ngFor="let item of navigationService.items$ | async" [item]="item"></hop-navigation-item>
  </div>

  <a *ngIf="!user" routerLink="/register" color="primary" mat-raised-button>{{ 'sign-up' | translate }}</a>

  <div *ngIf="user" class="px-1">
    <hop-toolbar-user [user]="user"></hop-toolbar-user>
  </div>
</div>

<!--<hop-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [class.hidden]="mobileQuery"></hop-navigation>-->
