import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { FeaturesState } from '../../state';
import { FeatureModel } from '../../models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
/*
*
<div>Using template:</div>
<ng-template
  [ifFeature]="'myFeature'"
  [ifFeatureElse]="featureFlagIsOff"
>
  <div>ON flag is ON</div>
</ng-template>
<ng-template #featureFlagIsOff><div>OFF flag is OFF</div></ng-template>
<div>Using shorthand</div>

<div *ifFeature="'myFeature';else featureFlagIsOff1">ON flag is ON</div>
<ng-template #featureFlagIsOff1><div>OFF flag is OFF</div></ng-template>

<button (click)="onToggleFlag()">Toggle!</button>

*
* */
@UntilDestroy()
@Directive({
  selector: '[ifFeature]'
})
export class IfFeatureFlagDirective<IfTemplate, ElseTemplate> {
  constructedForFlag?: string;
  elseTemplate?: TemplateRef<ElseTemplate>;
  flag?: string;
  prevValue = null;
  @Select(FeaturesState.selectFeatures) features$: Observable<FeatureModel>;
  private s?: Subscription;

  constructor(private template: TemplateRef<IfTemplate>, private viewContainerRef: ViewContainerRef, private store: Store) {}

  @Input('ifFeature')
  set ifFeatureFlag(flag: string) {
    this.flag = flag;
    this.updateView();
  }

  @Input('ifFeatureElse')
  set ifFeatureFlagNotPresent(t: TemplateRef<ElseTemplate>) {
    this.elseTemplate = t;
  }

  private updateView() {
    if (this.flag == null || this.s != null) {
      return;
    }

    this.s = this.features$.pipe(untilDestroyed(this)).subscribe((features) => {
      let on = features[this.flag];
      if (this.prevValue === on) {
        return;
      }
      this.viewContainerRef.clear();
      if (on) {
        this.viewContainerRef.createEmbeddedView(this.template);
        this.constructedForFlag = this.flag;
      } else if (this.elseTemplate != null) {
        this.viewContainerRef.createEmbeddedView(this.elseTemplate);
        this.constructedForFlag = this.flag;
      } else {
        this.viewContainerRef.clear();
        this.constructedForFlag = undefined;
      }
    });
  }

  ngOnDestroy() {
    if (typeof this.s?.unsubscribe === 'function') {
      this.s.unsubscribe();
    }
  }
}
