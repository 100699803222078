import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { User } from 'src/@hop/models';
import { UserGet, UserState } from 'src/@hop/state';
import { DialogCustomUrlComponent } from 'src/app/pages/pages/new-links/one-on-one/dialog-custom-url/dialog-custom-url.component';
import { AuthenticationService, UserService } from '../../../services';

@UntilDestroy()
@Component({
  selector: 'hop-dialog-verify-email',
  templateUrl: './dialog-verify-email.component.html',
  styleUrls: ['./dialog-verify-email.component.scss']
})
export class DialogVerifyEmailComponent implements OnInit {
  form: UntypedFormGroup;
  email;
  @Select(UserState.selectUser) user$: Observable<User>;

  constructor(
    public dialogRef: MatDialogRef<DialogCustomUrlComponent>,
    private fb: UntypedFormBuilder,
    private store: Store,
    private toastr: ToastrService,
    private authService: AuthenticationService
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = this.fb.group({
      verifyEmail: ['']
    });
    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.email = user.email;
    });
  }
  resendCode() {
    this.authService.resendEmailCode(this.f['verifyEmail'].value).pipe(untilDestroyed(this)).subscribe();
  }

  submit() {
    this.authService
      .verifyEmail(this.f['verifyEmail'].value)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogRef.close();
        // setTimeout(() => {
        this.store.dispatch(new UserGet());
        // }, 2000);
      });
  }

  get f() {
    return this.form.controls;
  }
}
