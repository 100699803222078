import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { ToolbarNotificationsModule } from './toolbar-notifications/toolbar-notifications.module';
import { ToolbarUserModule } from './toolbar-user/toolbar-user.module';
import { ToolbarSearchModule } from './toolbar-search/toolbar-search.module';
import { NavigationModule } from '../navigation/navigation.module';
import { RouterModule } from '@angular/router';
import { NavigationItemModule } from '../../components/navigation-item/navigation-item.module';
import { MegaMenuModule } from '../../components/mega-menu/mega-menu.module';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { SidenavItemModule } from '../sidenav/sidenav-item/sidenav-item.module';
import { LanguageMenuModule } from '../../components/language-menu/language-menu.module';
import { ButtonThemeDarkModule } from '../../components/button-theme-dark/button-theme-dark.module';
import { TranslateModule } from '@ngx-translate/core';
import { IsFeatureModule } from '../../components/is-feature/is-feature.module';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    ToolbarNotificationsModule,
    ToolbarUserModule,
    ToolbarSearchModule,

    NavigationModule,
    RouterModule,
    NavigationItemModule,
    MegaMenuModule,
    SidenavItemModule,
    LanguageMenuModule,
    ButtonThemeDarkModule,
    TranslateModule,
    IsFeatureModule
  ],
  exports: [ToolbarComponent]
})
export class ToolbarModule {}
