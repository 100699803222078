<div class="px-1 menu-button">
  <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
    <mat-icon [svgIcon]="'flag:' + nameLangFlag"></mat-icon>
  </button>
</div>

<mat-menu #languageMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item (click)="selectLang('en')">
    <mat-icon svgIcon="flag:en"></mat-icon>
    <span>English</span>
  </button>

  <button mat-menu-item (click)="selectLang('de')">
    <mat-icon svgIcon="flag:de"></mat-icon>
    <span>German</span>
  </button>

  <button mat-menu-item (click)="selectLang('es')">
    <mat-icon svgIcon="flag:es"></mat-icon>
    <span>Spanish</span>
  </button>

  <button mat-menu-item (click)="selectLang('ro')">
    <mat-icon svgIcon="flag:ro"></mat-icon>
    <span>Română</span>
  </button>
</mat-menu>
