<!-- <div class="footer flex">
  <div class="flex-auto flex items-center container">
    <a class="action flex-none"
       color="primary"
       href="//"
       id="get-hop"
       mat-flat-button>
      <mat-icon class="icon-sm ltr:mr-2 rtl:ml-2" svgIcon="mat:shopping_bag"></mat-icon>
      <span>Get Hop (Angular 14+)</span>
    </a>
    <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block test">
      Hop - Angular 14+ Material Design Admin Template - Save 100s of hours designing and coding
    </div>

  </div>
</div> -->

<div class="footer py-12 p-6" fxLayout="row">
  <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center" hopContainer class="container">
    <div class="flex flex-col space-y-16 items-center justify-between w-full md:flex-row md:space-y-0">
      <mat-icon svgIcon="logo:logo" class="icon-5xl hidden md:flex"></mat-icon>
      <div class="flex flex-col space-y-16 items-center md:flex-row md:space-y-0 md:space-x-20" *ngIf="!(user$|async)">
        <!-- Col 1 -->
        <div class="flex flex-col items-center w-full md:items-start" >
          <div class="mb-5 font-bold capitalize dark:">Company</div>
          <div class="flex flex-col items-center space-y-3 md:items-start">
            <a href="#" class="capitalize hover:text-cyan">About us</a>
            <a href="#" class="capitalize hover:text-cyan">Careers</a>
            <a href="#" class="capitalize hover:text-cyan">Analytics</a>
          </div>
        </div>

        <div class="flex flex-col items-center w-full md:items-start" >
          <div class="mb-5 font-bold capitalize">Popular Features</div>
          <div class="flex flex-col items-center space-y-3 md:items-start">
            <a href="#" class="capitalize hover:text-cyan">Booking page</a>
            <a href="#" class="capitalize hover:text-cyan">Group polls</a>
            <a href="#" class="capitalize hover:text-cyan">1:1 Meetings</a>
          </div>
        </div>

        <div class="flex flex-col items-center w-full md:items-start" >
          <div class="mb-5 font-bold capitalize">Features</div>
          <div class="flex flex-col items-center space-y-3 md:items-start">
            <a href="#" class="capitalize hover:text-cyan">Link shortening</a>
            <a href="#" class="capitalize hover:text-cyan">Branded links</a>
            <a href="#" class="capitalize hover:text-cyan">Analytics</a>
          </div>
        </div>
      </div>

      <div class="flex space-x-6">
        <a mat-icon-button type="button">
          <mat-icon svgIcon="social:facebook"></mat-icon>
        </a>

        <a mat-icon-button type="button">
          <mat-icon svgIcon="social:instagram"></mat-icon>
        </a>

        <a mat-icon-button type="button">
          <mat-icon svgIcon="social:twitter"></mat-icon>
        </a>

        <hop-language-menu></hop-language-menu>
        <hop-button-theme-dark></hop-button-theme-dark>
      </div>
    </div>

    <!-- <div class="ltr:ml-4 rtl:mr-4" fxHide fxShow.gt-sm>TopSejur Admin</div> -->
  </div>
</div>
