import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  constructor(private readonly updates: SwUpdate, private snackbar: MatSnackBar) {
    this.updates.available.subscribe((event) => {
      this.showAppUpdateAlert();
    });
    setInterval(() => {
      if (this.updates.isEnabled) {
        this.updates.checkForUpdate().then();
      }
    }, 5 * 60 * 1000);
  }
  showAppUpdateAlert() {
    const header = 'App Update available';
    const message = 'App Update available, Click Ok to update';
    const action = this.doAppUpdate;
    const caller = this;
    // Use MatDialog or ionicframework's AlertController or similar
    this.snackbar
      .open(message, 'Ok', {
        //duration: 3000000,
        panelClass: 'snackbar-primary'
      })
      .onAction()
      .subscribe(() => {
        this.doAppUpdate();
      });
  }
  doAppUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
