<ng-template #sidenavRef>
  <hop-sidenav [collapsed]="sidenavCollapsed$ | async"></hop-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <hop-toolbar
    [hasShadow]="toolbarShadowEnabled$ | async"
    [mobileQuery]="!(isMedium$ | async)"
    [user]="user$ | async"
    class="hop-toolbar"
  ></hop-toolbar>
</ng-template>

<ng-template #footerRef>
  <hop-footer *ngIf="isFooterVisible$ | async" class="hop-footer"></hop-footer>
</ng-template>

<ng-template #quickpanelRef>
  <hop-quickpanel></hop-quickpanel>
</ng-template>

<hop-layout [footerRef]="footerRef" [quickpanelRef]="quickpanelRef" [sidenavRef]="sidenavRef" [toolbarRef]="toolbarRef"></hop-layout>

<hop-config-panel-toggle *ifFeature="'isConfig'" (openConfig)="configpanel.open()"></hop-config-panel-toggle>

<!-- CONFIGPANEL -->
<hop-sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <hop-config-panel></hop-config-panel>
</hop-sidebar>
<!-- END CONFIGPANEL -->
