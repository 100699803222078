import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, NgxsOnInit, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { ColorSchemeName } from '../../config/colorSchemeName';
import { ConfigService } from '../../config/config.service';
import { debounceTime, Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApiNameSet, AppVersionSet, ColorSchemeSet, LanguageSet } from '../actions/app.action';
import { Language, LanguagesEnum } from 'src/@hop/models/language-model';
import { TranslateService } from '@ngx-translate/core';
import * as cookie from 'js-cookie';
import { Meta } from '@angular/platform-browser';

export class AppHopStateModel {
  colorScheme: ColorSchemeName;
  language: Language;
  apiName: string;
  appVersion: string;
}

@State<AppHopStateModel>({
  name: 'appState',
  defaults: {
    colorScheme: ColorSchemeName.light,
    language: {
      name: 'en',
      isManuallySet: false
    },
    apiName: 'default',
    appVersion: ''
  }
})
@UntilDestroy()
@Injectable()
export class AppHopState implements NgxsAfterBootstrap {
  @Select(AppHopState.selectColorScheme) colorScheme$: Observable<ColorSchemeName>;
  @Select(AppHopState.selectLanguage) language$: Observable<any>;
  @Select(AppHopState.selectApiName) apiName$: Observable<string>;

  constructor(private store: Store, private configService: ConfigService, private translate: TranslateService, private meta: Meta) {
    translate.setDefaultLang('en');

    this.colorScheme$.subscribe((colorScheme) => {
      // console.log('colorScheme', colorScheme);
      this.configService.updateConfig({
        style: {
          colorScheme
        }
      });
    });

    this.language$.pipe(debounceTime(200)).subscribe((language) => {
      // console.log('pune traducere', language);
      // if (language?.name) {
      translate.use(language?.name);
      cookie.set('lang', language?.name);
      // }
    });

    this.apiName$.subscribe((appName) => {
      if (appName && appName !== 'default') {
        return cookie.set('apiName', appName);
      }
      cookie.remove('apiName');
    });
    const versionTag = this.meta.getTag('name=version');
    setTimeout(() => {
      if (versionTag?.content) {
        this.store.dispatch(new AppVersionSet(versionTag?.content));
      }
    }, 1000);
  }

  ngxsAfterBootstrap(ctx: StateContext<any>): void {
    const navigatorLanguage = navigator.language.replace('-.+', '') as LanguagesEnum;
    // console.log('test', LanguagesEnum);
    if (Object.values(LanguagesEnum).includes(navigatorLanguage)) {
      this.store.dispatch(new LanguageSet({ name: navigatorLanguage, isManuallySet: false }));
    }
  }

  @Selector()
  static selectLanguage(state: AppHopStateModel) {
    return state.language;
  }

  @Selector()
  static selectColorScheme(state: AppHopStateModel) {
    return state.colorScheme;
  }

  @Selector()
  static selectApiName(state: AppHopStateModel) {
    return state.apiName;
  }

  @Selector()
  static selectAppVersion(state: AppHopStateModel) {
    return state.appVersion || null;
  }

  @Action(LanguageSet)
  setLanguage(ctx: StateContext<AppHopStateModel>, appState: AppHopStateModel) {
    const state = ctx.getState();
    if (window.history.replaceState) {
      //prevents browser from storing history with each change:
      window.history.replaceState({}, null, window.location.href.replace(/lang=../, 'lang=' + appState.language.name));
    }
    //console.log('make a choice');
    if (!appState.language.isManuallySet && state.language.isManuallySet) {
      //console.log('return');
      return;
    }
    ctx.setState({
      ...state,
      language: appState.language
    });
  }

  @Action(ColorSchemeSet)
  setColorScheme(ctx: StateContext<AppHopStateModel>, appState: AppHopStateModel) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      colorScheme: appState.colorScheme
    });
  }

  @Action(ApiNameSet)
  setApiName(ctx: StateContext<AppHopStateModel>, appState: AppHopStateModel) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      apiName: appState.apiName
    });
  }

  @Action(AppVersionSet)
  setAppVersion(ctx: StateContext<AppHopStateModel>, appState: AppHopStateModel) {
    if (appState.appVersion) {
      const state = ctx.getState();
      ctx.setState({
        ...state,
        appVersion: appState.appVersion
      });
    }
  }
}
