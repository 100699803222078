import { Params } from '@angular/router';

export class FeaturesGet {
  static readonly type = '[Features] Get';
}

export class FeatureSet {
  static readonly type = '[Features] Update';
  constructor(public name: string, public value: boolean) {}
}

export class FeatureRemove {
  static readonly type = '[Features] Remove';
  constructor(public name: string) {}
}

export class FeatureSetFromParams {
  static readonly type = '[Features] Set from params';
  constructor(public params: Params) {}
}
