import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, map, tap } from 'rxjs/operators';
import { UserAuth } from 'src/@hop/models/user-auth.model';
import { UserEdit, UserGet, UserLogin, UserLogout, UserUpdate } from '../actions/user.action';
import { User } from '../../models';
import { AuthenticationService, UserService } from '../../services';
import { UserEditModel } from 'src/@hop/models/user-edit.model';
import { Observable, of } from 'rxjs';

export class UserStateModel {
  user: User;
}

@State<UserStateModel>({
  name: 'userState',
  defaults: {
    user: null
  }
})
@Injectable()
export class UserState implements NgxsOnInit {
  ngxsOnInit(ctx: StateContext<UserStateModel>) {
    console.log('State initialized, now getting user');
    if (this.authenticationService.user$.value) {
      ctx.dispatch(new UserGet());
    }
  }
  constructor(private userService: UserService, private store: Store, private authenticationService: AuthenticationService) {
    this.authenticationService.user$.subscribe((user) => {
      this.store.dispatch(new UserUpdate(user));
    });
  }

  @Selector()
  static selectUser(state: UserStateModel) {
    return state.user;
  }

  @Action(UserGet)
  userGet(ctx: StateContext<UserStateModel>) {
    return this.userService.getUser().pipe(
      tap((returnData) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          user: { ...returnData } //here the data coming from the API will get assigned to the users variable inside the appstate
        });
      })
    );
  }
  @Action(UserUpdate)
  userUpdate(ctx: StateContext<UserStateModel>, data: { user: User }) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user: data.user
    });
  }

  @Action(UserLogin)
  userLogin(ctx: StateContext<UserStateModel>, user: UserAuth) {
    return this.authenticationService.login(user.email, user.password, user.rememberMe);
  }

  @Action(UserLogout)
  userLogout(ctx: StateContext<UserStateModel>, user: UserAuth) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user: null
    });
    return this.authenticationService.logout();
  }

  @Action(UserEdit)
  userEdit(ctx: StateContext<UserStateModel>, data: any): Observable<any> {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user: { ...data.user } //here the data coming from the API will get assigned to the users variable inside the appstate
    });
    return of(state);
  }
}
