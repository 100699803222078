import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as cookie from 'js-cookie';

import { User } from '../models';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(private router: Router, private http: HttpClient, @Inject('environment') private environment) {
    const auth: AuthenticationPayload = JSON.parse(cookie.get('auth') || '{}');
    if (auth.user) {
      this.user$.next(auth.user);
      this.jwtToken$.next(auth.jwt.token);
      this.jwtRefreshToken$.next(auth.jwt.refreshToken);
      this.startRefreshTokenTimer();
      // console.log(auth);
    }
  }

  public get user(): User {
    return this.user$.value;
  }

  user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  jwtToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private jwtRefreshToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public get jwtToken(): string {
    return this.jwtToken$.value;
  }

  // helper methods

  private refreshTokenTimeout;

  login(email: string, password: string, rememberMe = false): Observable<AuthenticationPayload> {
    return this.http
      .post<any>(
        `${this.environment.apiUrl}/login`,
        { username: email, password, rememberMe } // { withCredentials: true }
      )
      .pipe(
        map((data) => {
          this.user$.next(data.user);
          this.jwtToken$.next(data.jwt.token);
          this.jwtRefreshToken$.next(data.jwt.refreshToken);
          this.setAuthCookie(data);
          this.startRefreshTokenTimer();
          return data;
        })
      );
  }

  logout() {
    if (!this.jwtRefreshToken$.value) {
      return of(true);
    }
    this.http
      .post<any>(
        `${this.environment.apiUrl}/logout`,
        { refreshToken: this.jwtRefreshToken$.value }
        // { withCredentials: true }
      )
      .pipe(
        tap(() => {
          this.stopRefreshTokenTimer();
          this.user$.next(null);
          this.jwtToken$.next(null);
          this.jwtRefreshToken$.next(null);

          cookie.remove('lang');
          cookie.remove('auth');
          this.router.navigate(['/']);
          setTimeout(() => {
            localStorage.clear();
            location.reload();
          }, 500);
        })
      )
      .subscribe();
  }

  refreshToken(): Observable<AuthenticationPayload> {
    if (!this.jwtRefreshToken$.value || this.jwtToken$.value) {
      return of(null);
    }
    return this.http
      .post<any>(
        `${this.environment.apiUrl}/refresh-token`,
        { refreshToken: this.jwtRefreshToken$.value } // { withCredentials: true }
      )
      .pipe(
        map((data) => {
          this.user$.next(data.user);
          this.jwtToken$.next(data.jwt.token);
          data.jwt.refreshToken = this.jwtRefreshToken$.value;
          this.setAuthCookie(data);
          this.startRefreshTokenTimer();
          return data;
        })
      );
  }

  register(
    firstName: string,
    lastName: string,
    username: string,
    password: string,
    email: string,
    acceptTerms: boolean
  ): Observable<AuthenticationPayload> {
    return this.http
      .post<any>(
        `${this.environment.apiUrl}/register`,
        { firstName, lastName, username, password, email, acceptTerms } // { withCredentials: true }
      )
      .pipe(
        map((data) => {
          this.user$.next(data.user);
          this.jwtToken$.next(data.jwt.token);
          this.jwtRefreshToken$.next(data.jwt.refreshToken);
          this.setAuthCookie(data);
          this.startRefreshTokenTimer();
          return data;
        })
      );
  }

  verifyEmail(code): Observable<any> {
    // return of({ success: true, message: 'hey' });
    return this.http.post<any>(
      `${this.environment.apiUrl}/confirm-email`,
      { code } // { withCredentials: true }
    );
  }

  resendEmailCode(email): Observable<any> {
    return this.http.post<any>(
      `${this.environment.apiUrl}/confirm-email-resend`,
      { email } // { withCredentials: true }
    );
  }

  recoverPassword(email): Observable<any> {
    return this.http.post<any>(
      `${this.environment.apiUrl}/recover-password`,
      { email } // { withCredentials: true }
    );
  }

  resetPassword(email, code, password): Observable<any> {
    return this.http.post<any>(
      `${this.environment.apiUrl}/change-password`,
      { email, code, password } // { withCredentials: true }
    );
  }

  changePasswordUser(currentPassword, newPassword): Observable<any> {
    return this.http.post<any>(
      `${this.environment.apiUrl}/change-password-user`,
      { currentPassword, newPassword } // { withCredentials: true }
    );
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.jwtToken$.value.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  private setAuthCookie(data) {
    const isLocalhost = /localhost/.test(window.location.hostname);
    //console.log('isLocalhost', isLocalhost);
    cookie.set('auth', data, { expires: 183000, secure: true, sameSite: 'lax', domain: isLocalhost ? '' : 'koschedule.com' });
  }
}

export interface AuthenticationPayload {
  user: User;
  isAdmin?: boolean;
  jwt: {
    token: string;
    refreshToken?: string;
  };
}
