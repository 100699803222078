<div
  #originRef
  (click)="showPopover(originRef)"
  [class.bg-hover]="dropdownOpen"
  class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
  matRipple
>
  <div class="nameToolbar body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden md:block">{{ user?.firstName }} {{ user?.lastName }}</div>
  <div class="h-9 w-9 flex items-center justify-center text-primary">
    <img
      class="rounded-full w-9 h-9 justify-center m-auto"
      [src]="user?.image?.url ? user?.image?.url : 'https://www.koschedule.com/assets/img/placeholders/image_full.png'"
    />
  </div>
</div>
