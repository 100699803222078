export class Language {
  name: keyof typeof LanguagesEnum;
  isManuallySet: boolean;
}

export enum LanguagesEnum {
  en = 'en',
  de = 'de',
  ro = 'ro',
  es = 'es'
}
