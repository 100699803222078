import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfFeatureFlagDirective } from './is-feature.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IfFeatureFlagDirective],
  exports: [IfFeatureFlagDirective]
})
export class IsFeatureModule {}
