<div class="">
  <!-- <div class="flex w-xs">
    <h1 mat-dialog-title>Enter the code you received on email</h1>
    <div mat-dialog-actions>
      <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
    </div>
  </div> -->

  <div mat-dialog-title class="mb-6">Enter the code you received on email</div>

  <!-- <div class="flex justify-between">
    <div class="" mat-dialog-title>Enter the code you received on email</div>
    <button class="" mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
  </div> -->

  <!-- <div mat-dialog-title class="flex justify-between items-center">
    <div class="flex items-center">
    <h3>Enter the code you received on email</h3>
    <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
    </div>
  </div> -->

  <div mat-dialog-content>
    <div [formGroup]="form" class="flex flex-col space-y-4">
      <mat-form-field>
        <input formControlName="verifyEmail" matInput placeholder="Ex: 123456" />
        <!-- <mat-error *ngIf="form.get('verifyEmail').hasError('required')">Required</mat-error> -->
      </mat-form-field>
      <div class="flex flex-col space-y-4 md:flex-row md:justify-between md:space-y-0">
        <a (click)="resendCode()" class="w-full md:w-40" color="primary" mat-stroked-button>Resend Code</a>

        <a (click)="submit()" class="w-full md:w-40" color="primary" mat-raised-button [disabled]="!form.dirty || !form.valid">Save</a>
      </div>
    </div>
  </div>
</div>
