import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { FeatureSet } from 'src/@hop/state/actions/features.action';
import { FeatureModel } from 'src/@hop/models/feature.model';
import { FeaturesState } from 'src/@hop/state/states/features-state.service';
import { AppState } from '../../../app/state/states/app-state.service';
import { ApiNameSet } from '../../state';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'hop-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss']
})
export class QuickpanelComponent implements OnInit {
  @Select(FeaturesState.selectFeatures) features$: Observable<FeatureModel>;
  @Select(AppState.selectApiName) apiName$: Observable<string>;

  apiNames: string[] = ['default', 'www', 'www-dev', 'www-dev1', 'www-dev2', 'www-dev3', 'www-adu', 'www-iusti'];
  apiNameSelected = 'default';
  @Select(AppState.selectAppVersion) appVersion$: Observable<string>;

  constructor(private store: Store, @Inject('environment') public environment, private meta: Meta) {}

  ngOnInit() {}

  setFeatureValue(name, value) {
    this.store.dispatch(new FeatureSet(name, value));
    // console.log(name, value);
  }

  selectedApiName(apiName: string) {
    this.store.dispatch(new ApiNameSet(apiName));
  }

  refreshPage() {
    // @ts-ignore
    window.location = window.location.href + '?eraseCache=true&' + Math.random();
  }
  clearStorage() {
    localStorage.clear();
    this.refreshPage();
  }
}
