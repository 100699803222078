export class User {
  uuid: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isEmailVerified: boolean;
  image: {
    name: string;
    url: string;
    uuid: string;
    isPublic: boolean;
  };
  availabilityDefault: any;
}
